<template>
  <div>
    <ErrorMessage
      v-if="visibleErrorMessage"
      :text="messaggio"
      :titolo="titolo"
      @clearError="clearError"
    />

    <v-img
      class="mx-auto my-6"
      max-width="228"
      src="/logo.png?url"
    />
    <v-form v-model="isFormValid">
      <v-card

        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <div class="text-subtitle-1 text-medium-emphasis">
          Email
        </div>

        <v-text-field
          v-model="user.username"
          :rules="[rules.required]"
          density="compact"
          placeholder="Email address"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
        />

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
        </div>

        <v-text-field
          v-model="user.password"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          :rules="[rules.required]"
          @click:append-inner="visible = !visible"
        />

        <v-btn
          block
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          :disabled="!isFormValid"
          :loading="authLoading"
          @click="login()"
        >
          Log In
        </v-btn>
      </v-card>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useErrorMessageStored } from "~/store/errorMessageStored";
import { useAuthStored } from "~/store/authStored";

export default defineComponent({
  name: "login.vue",
  data () {
    return {
      visible: false,
      user: {
        username: null,
        password: null
      },
      isFormValid: false,
      rules: {
        required: value => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      meta: {
        auth: false
      }
    };
  },
  computed: {
    ...mapState(useErrorMessageStored, [
      "messaggio",
      "titolo",
      "visibleErrorMessage"
    ]),
    ...mapWritableState(useAuthStored, [
      "user_id",
      "nodo_id",
      "authenticated",
      "authLoading"
    ])
  },
  mounted () {
    document.title = "Login";
    // eslint-disable-next-line no-undef
    definePageMeta({
      layout: false,
      name: "login",
      title: "Login",
      auth: false
    });
  },
  methods: {
    ...mapActions(useAuthStored, ["authenticateUser", "logUserOut"]),
    ...mapActions(useErrorMessageStored, ["clearError"]),
    async login () {
      await this.authenticateUser(this.user);
      // redirect to homepage if user is authenticated
      if (this.authenticated) {
        this.$router.push("/");
      }
    }
  }
});
</script>
